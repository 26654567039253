import React from 'react'
import './style.scss'



const Footer = () => (
  <footer className="footer center has-background-grey-light">
    <div className="container">
      <div className="columns">
        <div className="column is-half">
          <p className="is-size-7 has-text-left">
            Unomia Pty Ltd ABN 48 606 086 542
            </p>
          <p className="is-size-7 has-text-left">
            Auckland, New Zealand | Perth, Western Australia
            </p>
        </div>
        <div className="column is-half has-text-right">
          <nav className="level">
              <div className="level-left">
                <p>&nbsp;</p>
              </div>
            <div className="level-right">
              <div className="level-item">
                <p className="is-size-7">
                  This page licensed under a Creative Commons Attribution 4.0 International license.
                  <a rel="license" href="https://creativecommons.org/licenses/by/4.0"> CC BY 4.0 </a>
                </p>
              </div>
              <div className="level-item">
                <a rel="license" href="https://creativecommons.org/licenses/by/4.0">
                  <figure className="image is-24x24">
                    <img src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt="CC BY 4.0 license" />
                  </figure>
                </a>
              </div>
              <div className="level-item">
                <a rel="license" href="https://creativecommons.org/licenses/by/4.0">
                  <figure className=" image is-24x24">
                    <img src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt="CC BY 4.0 license" />
                  </figure>
                </a>
              </div>
            </div>

          </nav>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
