import React from 'react';
import './style.scss';


const Contact = () => (
	<div className="has-background-light">
		<section className="section">
			<div className="container">
				<div className="level">
                    <div className="level-left">
                        <p>&nbsp;</p>
                    </div>
                    <div className="level-right">
                        <p className="level-item"><a className="button is-small is-link" href="mailto:contact@unomia.com?subject=Website enquiry">Contact</a></p>
                    </div>
                </div>
			</div>
		</section>
	</div>
);

export default Contact;
