import React from 'react';

import './style.scss';

const Header = ({ siteTitle }) => (
	<section className="hero gradientBg is-fullheight-with-navbar">
		<div className="hero-body">
			<div className="container center">
				<article className="media">
					<div className="media-content">
						<div className="content">
							<h1 className="is-uppercase center is-size-1 has-text-white">
								Unomia
							</h1>
							<p className="subtitle center has-text-white is-size-3">
								Higher Education Consulting
							</p>
							<p className="subtitle center has-text-white is-size-4">
								Australia | New Zealand
							</p>
						</div>
					</div>
				</article>
			</div>
		</div>
	</section>
);

export default Header;
