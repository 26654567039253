import React from 'react';
import {
	AiFillBank,
	AiOutlineGlobal,
	AiOutlineDesktop,
	AiOutlineAreaChart
} from 'react-icons/ai';
import { 
	BsBuilding,
	BsBriefcase
 } from 'react-icons/bs';

import './style.scss';


const Midsection = () => (
	<div>
		<section className="section">
			<div className="container">
				<div className="columns is-multiline">
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<AiFillBank size="2em" className="has-text-primary-dark" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Higher Education Consulting</h1>
									<p className="subtitle is-size-5">
									Deep industry expertise across public and private higher education institutions in Australia and New Zealand.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<AiOutlineAreaChart size="2em" className="has-text-link" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Compliance &amp; Reporting</h1>
									<p className="subtitle is-size-5">
									Institutional statutory and risk reporting, including TEQSA registration, re-registrations and TEFMA reporting.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<BsBriefcase size="2em" className="has-text-danger-dark" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Strategy &amp; Planning</h1>
									<p className="subtitle is-size-5">
										Strategy formulation, development and implementation. Emergent strategy, enterprise agility, roadmaps.  Capability based planning and value stream mapping.  Project and Programme Management.
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<AiOutlineGlobal size="2em" className="has-text-warning-dark"/>
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Governance, Policy &amp; Process</h1>
									<p className="subtitle is-size-5">
										Governance and policy framework development, customisation and implementation.  Monitoring and improvement.
									</p>
								</div>
							</div>
						</article>
					</div>

					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<BsBuilding size="2em" className="has-text-success-dark" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Facilities Management</h1>
									<p className="subtitle is-size-5">
										Facilities management, space planning, capital budget frameworks, preventative maintenance.  Project and Programme Management
									</p>
								</div>
							</div>
						</article>
					</div>
					<div className="column is-one-third">
						<article className="media">
							<figure className="media-left">
								<span className="icon is-medium">
									<AiOutlineDesktop size="2em" className="has-text-info" />
								</span>
							</figure>
							<div className="media-content">
								<div className="content">
									<h1 className="title is-size-4">Data</h1>
									<p className="subtitle is-size-5">
										Higher education data and information management.  Research data consulting.  Data architecture, governance, policy and managment.  
									</p>
								</div>
							</div>
						</article>
					</div>
				</div>
			</div>
		</section>
	</div>
);

export default Midsection;
